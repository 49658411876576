<template>
    <section class="page-wrap">
        <div class="content-part">
            <div class="container">
                <div class="row">
                    <div class="inner-banner other-pg-wrapper">
                        <h1>{{ page_title }}</h1>
                    </div>
                    <div class="content-paragraph mt-2" v-html="page_description"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from '../../services/helper'
export default {
    name: "DefaultPage",
    data(){
        return {
            page_slug:this.$route.params.slug,
            page_title:"",
            page_description:"",
        }
    },
    mounted() {
        let app = this;
        app.renderPage();
    },
    methods:{
        async renderPage(){
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl('/page/'+app.page_slug)).then(function (response) {
                if(response.data.success){
                    document.title = response.data.data.title;

                    app.page_title = response.data.data.title;
                    app.page_description = response.data.data.description;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        }
    },
    created() {
        this.$watch(() => this.$route.params,(newParam) => {
            this.page_slug = newParam.slug;
            this.renderPage();
        })
    },
}
</script>
